@use "@angular/material" as mat;

@include mat.core();

$my-primary-palette: (
  50: #e9e9ff,
  100:#c7c7ff,
  200: #9ea2ff,
  300: #707dff,
  400: #485dff,
  500: #003cff,
  600: #0032f3,
  700: #0024e6,
  800: #0011dc,
  900: #0000cd,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$my-primary: mat.define-palette($my-primary-palette);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-primary,
  ),
  typography: mat.define-typography-config(),
  density: 0,
 ));

 @include mat.all-component-themes($my-theme);


@media (prefers-color-scheme: dark) {
  $my-theme: mat.define-dark-theme((
   color: (
     primary: $my-primary,
     accent: $my-primary,
   ),
   typography: mat.define-typography-config(),
   density: 0,
  ));

  @include mat.all-component-colors($my-theme);
}

.mat-step-text-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--accent-color-lighter);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--accent-color-lighter3);
}

.mat-mdc-dialog-actions {
  padding: 0 1.5rem 1rem !important;
  display: flex !important;
  justify-content: flex-end !important;
}

mat-form-field {
  width: 100%;

  button {
    margin-right: 0.5rem;
  }
}
