@use "variables";
@use "mixins";

@use "material-override";

* {
  box-sizing: border-box;
}

:root {
  --max-w-page: 1100px;
  --foreground-rgb: 0, 0, 0;
  --background-rgb: 255, 255, 255;

  --accent-color: #{variables.$accent-color};

  --accent-color-darker: #{variables.$accent-color-darker};

  --accent-color-lighter: #{variables.$accent-color-lighter};
  --accent-color-lighter2: #{variables.$accent-color-lighter2};
  --accent-color-lighter3: #{variables.$accent-color-lighter3};

  @media (prefers-color-scheme: dark) {
    --foreground-rgb: 255, 255, 255;
    --background-rgb: 66, 66, 66;
  }
}

html,
body {
  min-height: 100%;
}

body {
  color: rgb(var(--foreground-rgb));
  background-color: rgb(var(--background-rgb));
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  max-width: 100%;

  &.login {
    background: url("/assets/images/background.webp"),
      linear-gradient(
        45deg,
        rgba(174, 205, 215, 1) 17%,
        rgba(253, 218, 222, 1) 69%,
        rgba(250, 224, 201, 1) 100%
      );

    background-position: center;
    background-repeat: no-repeat;
  }
}

.max-w-page {
  max-width: var(--max-w-page);
}

a {
  color: var(--accent-color);
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus-visible {
    color: var(--accent-color-darker);
  }
}

.leaflet-touch .leaflet-bar {
  border: 0;
}

.leaflet-touch .leaflet-bar a {
  border-bottom: 0;
  transition: 0.2s ease-in-out;
  @include mixins.blur-background(#fff, 0.6);

  &:hover,
  &:focus-visible {
    color: white;
    background-color: var(--accent-color);
  }

  &:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &:last-child {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

.leaflet-container .leaflet-control-attribution,
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  @include mixins.blur-background(#fff, 0.6);
}

.leaflet-container a.leaflet-popup-close-button {
  transition: 0.3s ease-in-out;

  &:hover {
    color: red;
  }
}

.empty[role="alert"] {
  padding: 2rem;
  text-align: center;
  color: gray;
  font-style: italic;
  height: 100%;
  display: flex;
  align-items: center;
}

.participant-status {
  &.pending {
    color: gray !important;
  }
  &.accepted {
    color: green !important;
  }
  &.maybe {
    color: orange !important;
  }
  &.declined {
    color: red !important;
  }
}

// QuillJS Editor
.ql-editor {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ql-toolbar {
  border-color: transparent;
}

.ql-container {
  border: 1px solid #9e9e9e;
  border-radius: var(--mdc-shape-small, 4px);

  &:hover {
    border-color: #222222;
  }

  &:focus-within {
    border-color: #003cff;
  }
}

.ql-toolbar + .ql-container {
  border-top: 1px solid #9e9e9e;
}
